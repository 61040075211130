module.exports = [{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/node_modules/gatsby-plugin-matomo/gatsby-browser'),
      options: {"plugins":[],"siteId":"1","siteUrl":"https://vrational.com","matomoUrl":"https://vrational.matomo.cloud","localScript":"/piwik.js"},
    },{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-115899394-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/krzysztofwrobel/Development/vRational/website-inspirations/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
