import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'

import styles from './Cookie.module.scss'

const Cookie = () => (
  <CookieConsent location="bottom" expires={150} containerClasses={styles.consentBanner}
                 contentClasses={styles.consentContent} buttonClasses={styles.consentButton} disableStyles={true}>
    <Link to={'/privacy'}>
      This site uses cookie. Read more.
    </Link>
  </CookieConsent>
)

export default Cookie
