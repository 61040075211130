import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import Networks from '../molecules/Networks'
import LogoUnit from '../molecules/LogoUnit'
import styles from './Header.module.scss'
import NavAppBar from '../molecules/NavAppBar'

import { Particles } from 'react-particles-js'

const query = graphql`
  query {
    dataYaml {
      title
    }
  }
`

export default class Header extends PureComponent {
  static propTypes = {
    minimal: PropTypes.bool.isRequired
  }

  state = {
    headerClasses: styles.header
  }

  componentDidMount() {
    this.toggleClasses()
  }

  componentDidUpdate() {
    this.toggleClasses()
  }

  toggleClasses = () => {
    if (this.props.minimal) {
      this.setState({ headerClasses: styles.minimal })
    } else {
      this.setState({ headerClasses: styles.header })
    }
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const meta = data.dataYaml
          const { minimal } = this.props

          return (
            <header className={this.state.headerClasses}>
              <Particles
                className={styles.background__image}
                params={{
                  particles: {
                    number: {
                      value: 80,
                      density: {
                        enable: true,
                        value_area: 1000
                      }
                    },
                    shape: {
                      type: 'edge',
                      stroke: {
                        width: 0
                      }
                    },
                    line_linked: {
                      enable: true,
                      distance: 200,
                      color: '#ff2c52',
                      opacity: 0.4,
                      width: 2
                    },
                    move: {
                      speed: 3
                    }
                  }
                }}
              />
              <NavAppBar show={minimal}/>
              <Link className={styles.header__link} to={'/'}>
                <LogoUnit minimal={minimal}/>
              </Link>
              <Networks hide={minimal} className={styles.content}/>
            </header>
          )
        }}
      />
    )
  }
}
