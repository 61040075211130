import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ThemeSwitch from './ThemeSwitch'

import styles from './NavAppBar.module.scss'
import { Link } from 'gatsby'

class NavAppBar extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool
  }

  state = {
    appBarClasses: `${styles.root} ${styles.hide}`,
    shouldShow: false
  }

  constructor(props) {
    super(props)

    this.lastScroll = null
    this.handleScroll = this.handleScroll.bind(this)
    // Alternatively, you can throttle scroll events to avoid
    // updating the state too often. Here using lodash.
    // this.handleScroll = _.throttle(this.handleScroll.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    this.setState({ lastScroll: window.scrollY })
    this.togglAppbarClasses(this.props.show)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(evt) {
    const lastScroll = window.scrollY

    if (lastScroll === this.lastScroll) {
      return
    }

    const shouldShow =
      this.lastScroll !== null ? lastScroll < this.lastScroll : null

    if (shouldShow !== this.state.shouldShow) {
      this.togglAppbarClasses(shouldShow)
    }

    this.lastScroll = lastScroll
  }

  togglAppbarClasses(show) {
    this.setState({
      appBarClasses: `${styles.root} ${
        show ? styles.show : styles.hide
        }`,
      shouldShow: show
    })
  }

  render() {
    return (
      <AppBar
        position="fixed"
        color="inherit"
        className={this.state.appBarClasses}
      >
        <Toolbar>
          <div className={styles.grow}/>
          <Link to={'/blog'}>
            <Button className={styles.menuButton}>
              Blog
            </Button>
          </Link>
          {/*<Button className={styles.menuButton} color="inherit" href="/team/">*/}
          {/*About us*/}
          {/*</Button>*/}
          {/*<Button variant="contained" className={styles.menuButton} color="primary" href="/team/">*/}
          {/*Contact us*/}
          {/*</Button>*/}
        </Toolbar>
      </AppBar>
    )
  }
}

export default NavAppBar
