import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import posed from 'react-pose'
import { Consumer } from '../../store/createContext'
import { fadeIn } from '../atoms/Transitions'

import { ReactComponent as Day } from '../../images/day.svg'
import { ReactComponent as Night } from '../../images/night.svg'
import styles from './ThemeSwitch.module.scss'

const Animation = posed.aside(fadeIn)

export default class ThemeSwitch extends PureComponent {
  render() {
    return (
      <Consumer>
        {({ dark, toggleDark }) => (
          <Fragment>
            <Helmet>
              <body className={dark ? 'dark' : null}/>
            </Helmet>
            <Animation className={styles.themeSwitch}>
              {dark ? <Day onClick={toggleDark()}/> : <Night onClick={toggleDark()}/>}
            </Animation>
          </Fragment>
        )}
      </Consumer>
    )
  }
}
